import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Figure from "../inputs/Figure";
import { Figure as IFigure } from "../interfaces/figure";
import { JSX } from 'react';
import { faInfo } from "@fortawesome/free-solid-svg-icons";

const FigureSection = ({figures, queue}: {figures: IFigure[], queue: string[][]}) => {
    const rows: JSX.Element[] = [];
    const deserializeQueue = (queue: string[]) => {
        let text = '';
        for(let i = 0; i < queue.length; i++) {
            text += (i + 1) + '. ' + queue[i] + '\n';
        } 
        return text;
    }

    const downloadTxtLog = (title: string, data: string): void => {
        const blob = new Blob([data], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = title + '.txt';
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
    }
    
    for(let i = 0; i < figures.length; i++) {
        const deserializedQueue = deserializeQueue(queue[i]);
        rows.push(
            <th key={i}>
                <Figure key={i} figure={figures[i]} />
                <FontAwesomeIcon icon={faInfo} cursor='pointer' title={deserializedQueue} onClick={() => downloadTxtLog(`Wyliczanie_${figures[i].x}_x_${figures[i].y}`, deserializedQueue)} />
            </th>
        );
    }

    return (
        <tr id='figure-section'>
            <th></th>
            {rows}
        </tr>
    );
}

export default FigureSection;