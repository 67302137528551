import InputSection from "./sections/InputSection";
import { Figure } from "./interfaces/figure";
import { Inputs } from "./interfaces/inputs";
import { PlateWeight } from "./interfaces/plateWeight";
import ResultTable from "./sections/ResultTable";
import { Error } from "./interfaces/error";
import { Material } from "./interfaces/material";
import HeaderSection from "./sections/HeaderSection";
import { useMemo } from "react";

const Calculator = ({inputs, plateWeight, materials, error, figures, isValidated, setError, setInputs, setValidated} : {
    inputs: Inputs, plateWeight: PlateWeight[], materials: Material[], error: Error, isValidated: boolean,
    figures: Figure[], setError: Function, setInputs: Function, setValidated: Function}) =>  {
    const queue: string[][] = useMemo(() => [[], [], [], [], [], [], [], []], []);

    return (
        <section id='calculator' className="flex flex-col gap-4 rounded-lg p-4">
            <HeaderSection error={error} />
            <div className="flex lg:gap-8 md:flex-row flex-col">
                <div className="w-auto">
                    <InputSection
                        error={error}
                        inputs={inputs}
                        plateWeight={plateWeight}
                        materials={materials}
                        figures={figures}
                        setError={setError}
                        setInputs={setInputs}
                        setValidated={setValidated}
                    />
                </div>
                <div className="flex-1">
                    <ResultTable inputs={inputs} figures={figures} isValidated={isValidated} queue={queue} />
                </div>
            </div>
        </section>
    );
}

export default Calculator;