const Select = ({id, val, data, setData, error}: { id: string, data: Map<number, number | string>, setData: Function, error: string, val: string | number}) => {
    return (
        <div className='flex flex-col'>
            <label className='font-bold' htmlFor={id}>{id}:</label>
            <select className='px-1 border-black border-2 rounded cursor-pointer py-1' onChange={e => {
                setData(e.target.value);
            }}>
                {Array.from(data.entries()).map(([key, value]) => (
                    <option key={key} value={value} selected={val === value}>
                        {value}
                    </option>
                ))}
            </select>
            <p>{error}</p>
        </div>
    );
}

export default Select;