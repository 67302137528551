import { useEffect, useMemo } from "react";
import Input from "../inputs/Input";
import Select from "../inputs/Select";
import { PlateWeight } from "../interfaces/plateWeight";
import { Inputs } from "../interfaces/inputs";
import { Error } from "../interfaces/error";
import { Material } from "../interfaces/material";
import { Figure } from "../interfaces/figure";
import { MinFormats } from "../interfaces/minFormats";
import Checkbox from "../inputs/Checkbox";

const InputSection = ({inputs, setInputs, materials, plateWeight, setValidated, error, setError, figures}:
    { inputs: Inputs, setInputs: Function, materials: Material[],
        plateWeight: PlateWeight[], setValidated: Function, error: Error, setError: Function,
        figures: Figure[]
    }) => {
    // Values provided by client
    const minFormats: MinFormats[] = useMemo(() => [
        {
            material: "PE",
            minThickness: 1,
            x: 50,
            y: 50,
        },
        {
            material: "PE",
            minThickness: 4,
            x: 30,
            y: 50,
        },
        {
            material: "PE",
            minThickness: 15,
            x: 15,
            y: 40,
        },
        {
            material: "PA6",
            minThickness: 1,
            x: 50,
            y: 50,
        },
        {
            material: "PA6",
            minThickness: 4,
            x: 30,
            y: 50,
        },
        {
            material: "PA6",
            minThickness: 15,
            x: 15,
            y: 40,
        },
        {
            material: "POM",
            minThickness: 1,
            x: 300,
            y: 300,
        },
        {
            material: "POM",
            minThickness: 4,
            x: 30,
            y: 50,
        },
        {
            material: "POM",
            minThickness: 15,
            x: 15,
            y: 40,
        },
        {
            material: "PA6G",
            minThickness: 1,
            x: 0,
            y: 0,
        },
    ], []);

    const maxY = Math.max(...figures.map(e => e.y));
    const maxX = Math.max(...figures.map(e => e.x));    

    const isObjectEmpty = (obj: object): boolean => {
        return !Object.values(obj).some(e => e !== '');
    }

    useEffect(() => {
        const errObject: Error = {
            material: '',
            amount: '',
            plateWeight: '',
            price: '',
            x: '',
            y: ''
        };
        const minFormat = minFormats.filter((e: MinFormats, number: number, array: MinFormats[]) => e.material === inputs.material.name && e.minThickness <= inputs.plateWeight.thickness).slice(-1)[0];
        if (inputs.x >= 0 && inputs.y >= 0) {
            const errValueMessage = `Formatka jest za mała na ten materiał.`;
            if(inputs.x < inputs.y) {
                if(inputs.x < Math.min(minFormat.x, minFormat.y))
                    errObject.x = errValueMessage;
                if(inputs.y < Math.max(minFormat.x, minFormat.y))
                    errObject.y = errValueMessage;
            } else {
                if(inputs.x < Math.max(minFormat.x, minFormat.y))
                    errObject.x = errValueMessage;
                if(inputs.y < Math.min(minFormat.x, minFormat.y))
                    errObject.y = errValueMessage;
            }
        }
        if(!figures.some(e => inputs.x <= e.x && inputs.y <= e.y)) {
            const errValueMessage = `Formatka jest za duża.`;
            if(inputs.x >= inputs.y) {
                errObject.x = errValueMessage;
            } else {
                errObject.y = errValueMessage;
            }
        }
        setValidated(isObjectEmpty(errObject));
        setError(errObject);
        //TODO: Bring back figures to dependency array.
    }, [inputs, setValidated, setError, minFormats]);

    return (
        <section id='input-section' className='gap-2 grid grid-cols-1'>
            <Select val={inputs.material.name} error={error.material} id='Typ materiału' data={new Map(materials.map((item) => [item.id, item.name]))} setData={(n: string) => {
                setInputs({
                    ...inputs,
                    material: materials.find(e => e.name === n),
                });
            }}/>
            <Select val={inputs.plateWeight.thickness} error={error.plateWeight} id='Grubość płyty [mm]' data={new Map(plateWeight.map((item) => [item.thickness, item.thickness]))} setData={(n: string) => {
                setInputs({
                    ...inputs,
                    plateWeight: plateWeight.find(e => e.thickness === parseInt(n)),
                });
            }}/>
            <Input value={inputs.x} error={error.x} setData={(n: number) => {
                if(n < 0) {
                    n = 0;
                } else if (n > maxX) {
                    n = maxX;
                }
                setInputs({
                    ...inputs,
                    x: n,
                });
            }} id='X [mm]'/>
            <Input value={inputs.y} error={error.y} setData={(n: number) => {
                if(n < 0) {
                    n = 0;
                } else if (n > maxY) {
                    n = maxY;
                }
                setInputs({
                    ...inputs,
                    y: n,
                });
            }} id='Y [mm]'/>
            <Input value={inputs.amount} error={error.amount} setData={(n: number) => {
                if(n != null && n < 0) {
                    n = 0;
                }
                setInputs({
                    ...inputs,
                    amount: n,
                });
            }} id='Ilość sztuk'/>
            <Input value={inputs.price} error={error.price} setData={(n: number) => {
                if(n != null && n < 0) {
                    n = 0;
                }
                setInputs({
                    ...inputs,
                    price: n,
                });
            }} id='Cena [zł/kg]'/>
            <Input value={inputs.cutPrice} error={''} setData={(n: number) => {
                if(n != null && n < 0) {
                    n = 0;
                }
                setInputs({
                    ...inputs,
                    cutPrice: n,
                });
            }} id='Cena cięcia [zł/mb]'/>
            <Checkbox value={!inputs.checkDimensions} error={''} setData={(n: boolean) => {
                setInputs({
                    ...inputs,
                    checkDimensions: !n,
                });
            }} id='Wyłącz sprawdzanie wymiarów'/>
        </section>
    );
}

export default InputSection;