const PresentationSection = ({priceAll, pricePerItem, optimalNumber}: {priceAll: number, pricePerItem: number, optimalNumber: number}) => {
    return (
        <section className="text-center flex flex-row items-center gap-8">
            <div className="flex flex-col">
                <h3 className="font-bold text-xl">Cena za całość</h3>
                <div className="my-3 border-[#00ff40] border-[3px] py-4 text-3xl">
                    {priceAll} zł
                </div>
            </div>
            <div className="flex flex-col">
                <h3 className="font-bold text-xl">Cena za sztukę</h3>
                <div className="my-3 border-[#00ff40] border-[3px] py-4 text-3xl">
                    {pricePerItem} zł
                </div>
            </div>
            <div className="flex flex-col">
                <h3 className="font-bold text-xl">Optymalna ilość sztuk</h3>
                <div className="my-3 border-[#00ff40] border-[3px] py-4 text-3xl">
                    {optimalNumber}
                </div>
            </div>
        </section>
    );
}

export default PresentationSection;