import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Calculator from './Calculator';
import { useState } from 'react';
import { Inputs } from './interfaces/inputs';
import { Error } from './interfaces/error';
import { Figure } from './interfaces/figure';
import { PlateWeight } from './interfaces/plateWeight';
import { Material } from './interfaces/material';
import { useCookies } from 'react-cookie';
import SettingsSection from './sections/SettingsSection';

function App() {
    const [isValidated, setValidated] = useState(true);
    const [cookies, setCookie] = useCookies();

    // Default values for figures provided by client.
    const defaultFigures: Figure[] = [
        {id: 0, x: 1015, y: 2030, isVertical: true, material: 'POM'},
        {id: 1, x: 2030, y: 1015, isVertical: true, material: 'POM'},
        {id: 2, x: 1250, y: 3000, isVertical: true, material: 'POM'},
        {id: 3, x: 3000, y: 1250, isVertical: true, material: 'POM'},
        {id: 4, x: 1015, y: 2030, isVertical: false, material: 'POM'},
        {id: 5, x: 2030, y: 1015, isVertical: false, material: 'POM'},
        {id: 6, x: 1250, y: 3000, isVertical: false, material: 'POM'},
        {id: 7, x: 3000, y: 1250, isVertical: false, material: 'POM'},
        {id: 8, x: 1015, y: 2030, isVertical: true, material: 'PE'},
        {id: 9, x: 2030, y: 1015, isVertical: true, material: 'PE'},
        {id: 10, x: 1250, y: 3000, isVertical: true, material: 'PE'},
        {id: 11, x: 3000, y: 1250, isVertical: true, material: 'PE'},
        {id: 12, x: 1015, y: 2030, isVertical: false, material: 'PE'},
        {id: 13, x: 2030, y: 1015, isVertical: false, material: 'PE'},
        {id: 14, x: 1250, y: 3000, isVertical: false, material: 'PE'},
        {id: 15, x: 3000, y: 1250, isVertical: false, material: 'PE'},
        {id: 16, x: 1015, y: 2030, isVertical: true, material: 'PA6'},
        {id: 17, x: 2030, y: 1015, isVertical: true, material: 'PA6'},
        {id: 18, x: 1250, y: 3000, isVertical: true, material: 'PA6'},
        {id: 19, x: 3000, y: 1250, isVertical: true, material: 'PA6'},
        {id: 20, x: 1015, y: 2030, isVertical: false, material: 'PA6'},
        {id: 21, x: 2030, y: 1015, isVertical: false, material: 'PA6'},
        {id: 22, x: 1250, y: 3000, isVertical: false, material: 'PA6'},
        {id: 23, x: 3000, y: 1250, isVertical: false, material: 'PA6'},
        {id: 24, x: 1015, y: 2030, isVertical: true, material: 'PA6G'},
        {id: 25, x: 2030, y: 1015, isVertical: true, material: 'PA6G'},
        {id: 26, x: 1250, y: 3000, isVertical: true, material: 'PA6G'},
        {id: 27, x: 3000, y: 1250, isVertical: true, material: 'PA6G'},
        {id: 28, x: 1015, y: 2030, isVertical: false, material: 'PA6G'},
        {id: 29, x: 2030, y: 1015, isVertical: false, material: 'PA6G'},
        {id: 30, x: 1250, y: 3000, isVertical: false, material: 'PA6G'},
        {id: 31, x: 3000, y: 1250, isVertical: false, material: 'PA6G'},
    ];
    
    // Default values provided by producers of materials.
    const defaultPlateWeightsValues: PlateWeight[] =  [
        {thickness: 1, weightPOM: 3, weightPE: 2,  weightPA6: 2.46, weightPA6G: 0},
        {thickness: 2, weightPOM: 5.98, weightPE: 4, weightPA6: 5.2, weightPA6G: 0},
        {thickness: 3, weightPOM: 8.98, weightPE: 6, weightPA6: 7.74, weightPA6G: 0},
        {thickness: 4, weightPOM: 11.96, weightPE: 8, weightPA6: 10.16, weightPA6G: 0},
        {thickness: 5, weightPOM: 14.96, weightPE: 10, weightPA6: 12.1, weightPA6G: 0},
        {thickness: 6, weightPOM: 17.94, weightPE: 12, weightPA6: 15.12, weightPA6G: 17.5},
        {thickness: 8, weightPOM: 25.2, weightPE: 16, weightPA6: 21.53, weightPA6G: 22},
        {thickness: 10, weightPOM: 31.1, weightPE: 20, weightPA6: 26.37, weightPA6G: 27.5},
        {thickness: 12, weightPOM: 38.04, weightPE: 24, weightPA6: 32.65, weightPA6G: 32},
        {thickness: 15, weightPOM: 46.88, weightPE: 30, weightPA6: 39.91, weightPA6G: 39.5},
        {thickness: 16, weightPOM: 49.82, weightPE: 32, weightPA6: 0, weightPA6G: 42},
        {thickness: 18, weightPOM: 0, weightPE: 36, weightPA6: 0, weightPA6G: 48.4},
        {thickness: 20, weightPOM: 61.62, weightPE: 40, weightPA6: 52.01, weightPA6G: 51},
        {thickness: 25, weightPOM: 76.36, weightPE: 50, weightPA6: 64.1, weightPA6G: 62.5},
        {thickness: 30, weightPOM: 92.88, weightPE: 60, weightPA6: 76.19, weightPA6G: 75},
        {thickness: 35, weightPOM: 107.62, weightPE: 70, weightPA6: 90.71, weightPA6G: 87},
        {thickness: 40, weightPOM: 122.36, weightPE: 80, weightPA6: 102.8, weightPA6G: 99},
        {thickness: 45, weightPOM: 137.1, weightPE: 90, weightPA6: 0, weightPA6G: 112},
        {thickness: 50, weightPOM: 151.84, weightPE: 100, weightPA6: 126.99, weightPA6G: 123},
        {thickness: 55, weightPOM: 173.96, weightPE: 110, weightPA6: 0, weightPA6G: 136},
        {thickness: 60, weightPOM: 182.8, weightPE: 120, weightPA6: 153.6, weightPA6G: 149},
        {thickness: 65, weightPOM: 197.54, weightPE: 130, weightPA6: 0, weightPA6G: 161},
        {thickness: 70, weightPOM: 212.28, weightPE: 140, weightPA6: 177.79, weightPA6G: 173},
        {thickness: 75, weightPOM: 230.56, weightPE: 150, weightPA6: 0, weightPA6G: 185},
        {thickness: 80, weightPOM: 243.98, weightPE: 160, weightPA6: 201.97, weightPA6G: 197.5},
        {thickness: 90, weightPOM: 273.46, weightPE: 180, weightPA6: 223.07, weightPA6G: 222},
        {thickness: 100, weightPOM: 302.94, weightPE: 200, weightPA6: 249.303, weightPA6G: 246.5},
    ];

    const materials: Material[] = [
        {id: 1, name: "POM"},
        {id: 2, name: "PE"},
        {id: 3, name: "PA6"},
        {id: 4, name: "PA6G"},
    ];

    const loadPlates = () => {
        if(cookies.plates_2 != null) {
            return cookies.plates_2;
        }
        return defaultPlateWeightsValues;
    }

    const loadFigures = () => {
        if(cookies.figures_2 != null) {
            return cookies.figures_2;
        }
        return defaultFigures;
    }

    const [figures, setFigures] = useState<Figure[]>(loadFigures());
    const [plateWeight, setPlateWeight] = useState<PlateWeight[]>(loadPlates());

    const updatePlateWeight = (plateWeight: PlateWeight[] | undefined) => {
        if(plateWeight === undefined) {
            plateWeight = defaultPlateWeightsValues;
        }
        const plates = JSON.stringify(plateWeight);
        setCookie('plates_2', plates, { path: '/' });
        setPlateWeight(plateWeight);
        setInputs({...inputs, 
            plateWeight: plateWeight.find(e => e.thickness === inputs.plateWeight.thickness)!,
        });
    }

    const updateFigures = (figures: Figure[] | undefined) => {
        if(figures === undefined) {
            figures = [...defaultFigures];
        }
        setCookie('figures_2', JSON.stringify(figures), { path: '/' });
        setFigures([...figures]);
    }

    const [inputs, setInputs] = useState<Inputs>({
        material: materials[0],
        plateWeight: plateWeight[0],
        amount: 0,
        price: 0,
        x: 20,
        y: 20,
        cutPrice: 3,
        checkDimensions: true
    });

    const [error, setError] = useState<Error>({
        material: '',
        amount: '',
        plateWeight: '',
        price: '',
        x: '',
        y: ''
    });

    return (
        <main className='App'>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={
                        <Calculator
                            error={error}
                            figures={figures.filter(e => e.material === inputs.material.name)}
                            inputs={inputs}
                            isValidated={isValidated}
                            materials={materials}
                            plateWeight={plateWeight}
                            setError={setError}
                            setInputs={setInputs}
                            setValidated={setValidated}
                        />
                    }></Route>
                    <Route path="/settings" element={
                        <SettingsSection
                            figures={figures}
                            materials={materials}
                            plateWeight={plateWeight}
                            setFigures={updateFigures}
                            setPlateWeight={updatePlateWeight}
                        />
                    }></Route>
                </Routes>
            </BrowserRouter>
        </main>
    );
}

export default App;
