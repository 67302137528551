const Input = ({id, value, setData, error}: {id: string, value: number, setData: Function, error: string}) => {
    return (
        <div className='flex flex-col'>
            <label className={'font-bold ' + (error.length > 0 ? 'text-red-600' : '')} htmlFor={id}>{id}:</label>
            <input value={value} type='number' id={id} className={'px-1 ' + (error.length > 0 ? 'border-red-600':  'border-black') + ' border-2 rounded'} onChange={e => {
                setData(parseFloat(e.target.value));
            }}/>
        </div>
    );
}

export default Input;