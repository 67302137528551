import SettingsDialog from './SettingsDialog';
import { PlateWeight } from '../interfaces/plateWeight';
import { Material } from '../interfaces/material';
import { Figure } from '../interfaces/figure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRotateLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SettingsSection = ({plateWeight, materials, setPlateWeight, figures, setFigures} : {plateWeight: PlateWeight[], materials: Material[],
    setPlateWeight: Function, figures: Figure[], setFigures: Function}) => {
    const navigate = useNavigate();

    const ReturnTable = ({material} : {material: string}): JSX.Element => {
        const [tFigures, setTFigures] = useState<Figure[]>(figures.filter(e => e.material === material).slice(0, 4));
        return (
            <table className="rounded-lg settingsTable">
                <thead>
                    <tr>
                        <th colSpan={5}>
                            Zmiana wymiaru formatki: {material}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>x</td>
                        {figures.filter(e => e.material === material).slice(0, 4).map((e, i) =>
                            <td><input key={e.id} type='number' className='w-[100%]' value={tFigures[i].x} onChange={evt => {
                            const value: number = parseInt(evt.currentTarget.value);
                            if(value > 0) {
                                tFigures[i].x = value;
                                setTFigures([...tFigures]);
                            }
                        }} /></td>)}
                    </tr>
                    <tr>
                        <td>y</td>
                        {figures.filter(e => e.material === material).slice(0, 4).map((e, i) =>
                            <td><input key={i} type='number' className='w-[100%]' value={tFigures[i].y}  onChange={evt => {
                            const value: number = parseInt(evt.currentTarget.value);
                            if(value > 0) {
                                tFigures[i].y = value;
                                setTFigures([...tFigures]);
                            }
                        }} /></td>)}
                    </tr>
                </tbody>
            </table>
        );
    }

    return (
        <div className='flex flex-col'>
            <div className="flex justify-between px-4">
                <button className="bg-blue-600 text-white p-2 rounded-md" onClick={e => navigate("/?admin=1")}>
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                    &nbsp; Powrót
                </button>
                <div className='flex justify-between'>
                    <button className="bg-blue-600 text-white p-2 rounded-md" onClick={e => {
                        setFigures();
                        setPlateWeight();
                        toast('Przywrócono do wartości domyślnych!',  {
                            position: "bottom-center",
                            autoClose: 3000,
                            closeOnClick: true,
                            theme: 'dark',
                            progressStyle: {backgroundColor: 'blue'},
                            style: {backgroundColor: 'rgb(37 99 235)'}
                        });
                    }}>
                        Przywróć do wartości domyślnych &nbsp;
                        <FontAwesomeIcon icon={faArrowRotateLeft} />
                    </button>
                    <button className="bg-green-600 text-white p-2 rounded-md mx-16" onClick={e => {
                        setFigures(figures);
                        setPlateWeight(plateWeight);
                        toast('Zapisano!',  {
                            position: "bottom-center",
                            autoClose: 3000,
                            closeOnClick: true,
                            theme: 'dark',
                            progressStyle: {backgroundColor: 'green'},
                            style: {backgroundColor: 'rgb(22 163 74)'}
                        });
                    }}>
                        Zapisz &nbsp;
                        <FontAwesomeIcon icon={faSave} />
                    </button>
                </div>
            </div>
            <ToastContainer />
            <div className='flex justify-between'>
                <div className='w-[30%] rounded-lg ml-1 mt-[0%]'>
                    <SettingsDialog materials={materials} plateWeight={plateWeight} setPlateWeight={setPlateWeight} />
                </div>
                <div className='w-[30%] mr-0 text-lg'>
                    <ReturnTable material='POM' />
                    <ReturnTable material='PE' />
                    <ReturnTable material='PA6' />
                    <ReturnTable material='PA6G' />
                </div>
            </div>
        </div>
    );
}

export default SettingsSection;